import PropTypes from "prop-types"
import React from "react"
import { Flex, Text } from "rebass"

const Dot = ({ color, small }) => (
  <div
    style={{
      width: small ? 10 : 12,
      height: small ? 10 : 12,
      borderRadius: 6,
      backgroundColor: color,
      marginRight: 3,
      marginTop: small ? 4 : 0
    }}
  />
)

const Title = ({ children, small, color = '#712911', background = 'transparent', button = false }) => (
  <Flex
    alignItems='center'
    style={{
      margin: '1.45rem 0',
      background: background,
      borderRadius: 6,
      padding: button ? '2px 12px 4px 12px' : 0
    }}
  >
    <Dot small={small} color='#a02118' />
    <Dot small={small} color='#b88601' />
    <Dot small={small} color='#683019' />
    <Text
      style={{
        marginLeft: 10
      }}
      fontSize={small ? 2 : [3, 4]}
      fontWeight='bold'
      color={color}>
      {children}
    </Text>
  </Flex>
)

Title.propTypes = {
  children: PropTypes.array,
  small: PropTypes.bool
}

Title.defaultProps = {
  children: <></>,
  small: false
}

export default Title
