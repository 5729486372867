/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Flex, Box } from "rebass"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1280,
          paddingTop: 0
        }}
      >
        <main>{children}</main>
      </div>
      <footer style={{ background: '#f6f0ee' }}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            paddingTop: 0,
          }}
        >
          <Flex
            flexWrap='wrap'
            p={[3, 4]}
            style={{
              fontSize: 12,
              lineHeight: 1.4,
              color: '#712912'
            }}
          >
            <Box py={2} width={[1/2, 1/4]}>
              Telemannstr. 11<br />
              60323 Frankfurt am Main<br /><br />

              T   +49 69 972 66 517<br />
              M  +49 178 63 58 930<br /><br />

              info@tcm-annavonkluechtzner.de
            </Box>
            <Box py={2} width={[1/2, 1/4]}></Box>
            <Box py={2} width={[1/2, 1/4]}>
              Copyright 2019<br />
              Anna von Klüchtzner<br />
              Alle Rechte vorbehalten<br /><br />

              <Link to="/impressum">Impressum</Link>
            </Box>
            <Box py={2} width={[1/2, 1/4]}>
              <Link to="/">Home</Link><br />
              <Link to="/tcm">TCM</Link><br />
              <Link to="/anwendungsgebiete">Anwendungsgebiete</Link><br />
              <Link to="/ueber-mich">Über mich</Link><br />
              <Link to="/faq">FAQ</Link><br />
              <Link to="/kontakt">Kontakt</Link>
            </Box>
          </Flex>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
