import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Flex, Box } from "rebass"
import { push as Menu } from "react-burger-menu"

const NavLink = ({ children, href }) =>
  <Link
    className="menu-item"
    to={href}
    activeStyle={{
      color: '#a02118',
      borderBottom: '4px solid #a02118',
    }}>
    {children}
  </Link>

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 253, height: 135) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const menu = (
    <>
      <NavLink href='/tcm'>
        TCM
      </NavLink>
      <NavLink href='/anwendungsgebiete'>
        Anwendungsgebiete
      </NavLink>
      <NavLink href='/ueber-mich'>
        Über mich
      </NavLink>
      <NavLink href='/faq'>
        FAQ
      </NavLink>
      <NavLink href='/kontakt'>
        Kontakt
      </NavLink>
    </>
  );

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <Flex
        alignItems='center'
        style={{
          margin: `0 auto`,
          maxWidth: 1280,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >
          <Img fixed={data.file.childImageSharp.fixed} />
        </Link>
        <Box mx='auto' />
        <div className="menu">
          {menu}
        </div>
        <Menu width={'100%'}>
          {menu}
        </Menu>
      </Flex>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
